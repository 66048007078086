<template>
  <div class="app-container pe-background">
    <ComponentHeader :isMobile="isMobile" />
    <div class="content">
      <router-view :key="$route.fullPath"></router-view>
    </div>
    <ComponentFooter :isMobile="isMobile" />
  </div>
</template>

<script>
import "@/assets/pe-colors.css";
import "@/assets/pe-style.css";
import ComponentHeader from "@/components/ComponentHeader.vue";
import ComponentFooter from "@/components/ComponentFooter.vue";

export default {
  components: {
    ComponentHeader,
    ComponentFooter
  },
  data() {
    return {
      isMobile: false
    };
  },
  created() {
    document.title = "PASSON TECH";
    this.checkIsMobile();
    window.addEventListener('resize', this.checkIsMobile);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkIsMobile);
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.innerWidth < 820;
    }
  }
};
</script>
<style>
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "Helvetica", Arial, sans-serif;
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allows this container to expand to fill available space */
}

.content {
  flex-grow: 1; /* Ensures content expands to push the footer down */
}
</style>