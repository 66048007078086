<template>
<div class="header-container">
  <div class="header-content pe-max-width">
    <!-- Hamburger Menu Button Mobile -->
    <button @click="toggleMobileMenu" class="hamburger-button" v-if="this.isMobile">
      <div class="hamburger" :class="{ 'active': showMobileMenu }">
        <span class="bar"></span>
        <span class="bar"></span>
        <span class="bar"></span>
      </div>
    </button>

    <!-- Logo Container Mobile -->
    <div class="logo-container-mobile" v-if="this.isMobile">
      <img src="@/assets/passon_tech_logo_v9_transp.png" alt="Passon Tech Logo" class="company-logo"/>
    </div>

    <!-- Logo Container Desktop -->
    <div class="logo-container-desktop" v-if="!this.isMobile">
      <img src="@/assets/passon_tech_logo_v9_transp.png" alt="Passon Tech Logo" class="company-logo"/>
    </div>

    <!-- Menu Container Desktop -->
    <div class="menu-container" v-if="!this.isMobile">
      <router-link v-for="(navItem, index) in visibleMenuItems" :key="'menu-item-' + index" :to="navItem.link" class="menu-item">
        <b>{{ navItem.item }}</b>
      </router-link>
    </div>

    <!-- Menu Container Mobile -->
    <div class="menu-container" v-if="this.isMobile && this.showMobileMenu" :class="{ 'mobile-active': showMobileMenu }">
      <router-link v-for="(navItem, index) in visibleMenuItems"
                  :key="'menu-item-' + index"
                  :to="navItem.link"
                  class="menu-item"
                  @click="closeMobileMenu">
        <b>{{ navItem.item }}</b>
      </router-link>
    </div>

    <!-- Language Container -->
    <div class="language-container">
      <!-- Dropdown -->
      <div class="menu-item hidden-menu-container">
        <div @click="toggleLanguageMenu">
          <b>{{ displayLanguage }}</b> <span class="pe-icon-arrow-down"></span>
        </div>
        <div class="hidden-menu">
          <div v-for="(item, index) in this.language.items" :key="'more-menu-item-' + index" class="hidden-menu-item" @click="changeLanguage(item)">
            <b>{{ item.toUpperCase() }}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>   
</template>
<script>
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      language: {
        items: ["de", "en"],
      },
      header: {
        en: [
          { item: "Home", link: "/en/home" },
          { item: "Projects", link: "/en/projects" },
          { item: "Articles", link: "/en/articles" },
          { item: "About", link: "/en/about" },
          { item: "Contact", link: "/en/contact" },
        ],
        de: [
          { item: "Home", link: "/de/home" },
          { item: "Projekte", link: "/de/projects" },
          { item: "Artikel", link: "/de/articles" },
          { item: "Über", link: "/de/about" },
          { item: "Kontakt", link: "/de/contact" },
        ]
      },
      showMobileMenu: false,
    };
  },
  computed: {
    visibleMenuItems() {
      const lang = this.$route.params.lang || 'en';
      const menuItems = this.header[lang] || this.header['en'];
      return menuItems;
    },
    displayLanguage() {
      const defaultLang = 'EN';
      // Check if the route param exists and is one of the known languages
      if (this.language.items.includes(this.$route.params.lang)) {
        return this.$route.params.lang.toUpperCase();
      }
      return defaultLang;
    },
  },
  methods: {
    toggleMobileMenu() {
      this.showMobileMenu = !this.showMobileMenu;
    },
    closeMobileMenu() {
      this.showMobileMenu = false;
    },
    changeLanguage(selectedLang) {
      // Get the current path and query
      const path = this.$route.path;
      const query = this.$route.query;
      // Construct the new path with the selected language
      const newPath = path.replace(/^\/[a-z]{2}/, `/${selectedLang}`);
      // Construct the full URL
      const newUrl = `${window.location.origin}${newPath}${this.queryString(query)}`;
      // Redirect to the new URL
      window.location.href = newUrl;
    },
    queryString(query) {
      const queryStrings = Object.entries(query).map(([key, value]) => `${key}=${value}`);
      return queryStrings.length > 0 ? `?${queryStrings.join('&')}` : '';
    }
  },
};
</script>
<style scoped>
.header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pe-color-ghost-white);
  color: var(--pe-color-off-black);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  height: 46px;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 16px;
}

.language-container {
  padding: 0px;
  display: flex;
  align-items: center;
}

.logo-container-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.logo-container-desktop {
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 15px;
}

.company-logo {
  height: 35px;
}

.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.menu-item, .hidden-menu-item {
  list-style-type: none;
  color: var(--pe-color-off-black);
  font-size: 16px;
  white-space: nowrap;
  text-decoration: none;
  cursor: pointer;
  padding: 13px 15px;
  background-color: var(--pe-color-ghost-white);
  display: block;
}

.menu-item:hover, .hidden-menu-item:hover {
  color: var(--pe-color-pcb-green);
}

.hidden-menu-container {
  position: relative;
}

.hidden-link-menu, .hidden-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  background-color: var(--pe-color-ghost-white);
}

.hidden-menu-container:hover .hidden-link-menu,
.hidden-menu-container:hover .hidden-menu {
  display: block;
}

.hamburger-button {
  background: none;
  border: none;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  touch-action: manipulation;
}

.hamburger {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
}

.bar {
  height: 3px;
  width: 100%;
  background-color: var(--pe-color-off-black);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.hamburger.active .bar:nth-child(1) {
  transform: translateY(8.5px) rotate(45deg);
}

.hamburger.active .bar:nth-child(2) {
  opacity: 0;
}

.hamburger.active .bar:nth-child(3) {
  transform: translateY(-8.5px) rotate(-45deg);
}

.hamburger-button {
  display: block;
}
 
.menu-container.mobile-active {
  display: flex; 
  flex-direction: column; 
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: var(--pe-color-ghost-white);
  padding-top: 20px;
}
</style>