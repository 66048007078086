<template>
  <div>
    <div class="footer-upper">
      <!-- Links for Mobile: Each link in its own row -->
      <div v-if="isMobile">
        <div class="footer-row" v-for="(navItem, index) in footer[this.$route.params.lang]" :key="'mobile-footer-link-' + index">
          <router-link class="mobile-footer-link" :to="navItem.link">
            <b>{{ navItem.item }}</b>
          </router-link>
        </div>
      </div>
      <!-- Links for Desktop: All links in one row -->
      <div class="footer-row" v-if="!isMobile">
        <div class="footer-links">
          <router-link v-for="(navItem, index) in footer[this.$route.params.lang]" :key="'footer-link-' + index" :to="navItem.link">
            <b>{{ navItem.item }}</b>
          </router-link>
        </div>
      </div>
    </div>
    <hr class="footer-divider"/>
    <div v-if="lang === 'de'" class="footer-lower">
      Website-Design und technische Umsetzung durch Passon Tech
    </div>
    <div v-if="lang === 'en'" class="footer-lower">
      Website Design and Technical Realization by Passon Tech
    </div>
    <div class="footer-lower">
      <div class="bottom pe-min-width">
        <div class="footer-left">
          <div>&copy; 2023 - 2024 Passon Tech</div>
        </div>
        <div class="footer-icons">
          <a href="https://www.linkedin.com/in/thomas-passon">
            <img src="@/assets/LI-In-Bug.png" alt="linkedin" class="icon"/>
          </a>
          <a href="https://github.com/passon-tech">
            <img src="@/assets/github-mark-white.svg" alt="github" class="icon"/>
          </a>
        </div>
      </div>
    </div>
  </div>   
</template>
<script>
export default {
  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lang() {
      return this.$route.params.lang;
    }
  },
  data() {
    return {
      footer: {
        en: [
          { item: "Privacy", link: "/en/privacy" },
          { item: "Terms", link: "/en/terms" },
          { item: "Imprint", link: "/en/imprint" },
          { item: "Contact", link: "/en/contact" },
        ],
        de: [
          { item: "Datenschutz", link: "/de/privacy" },
          { item: "Nutzungsbedingungen", link: "/de/terms" },
          { item: "Impressum", link: "/de/imprint" },
          { item: "Kontakt", link: "/de/contact" },
        ]
      },
    };
  },
};
</script>
<style scoped>
.footer-upper {
  display: flex;
  flex-direction: column;
  margin: 0 auto; /* Centering horizontally */
  font-size: 0.9rem;
  padding: 0 15px;
}

.footer-upper, .footer-lower, .footer-row {
  background-color: var(--pe-color-off-black);
}

.footer-links, .mobile-footer-link {
  display: flex;
  justify-content: center;
  text-decoration: none;
  flex-grow: 1;
  color: var(--pe-color-lunar-white);
}
.footer-links a, .footer-row .mobile-footer-link a {
  text-decoration: none;
  margin: 0 25px;
  color: var(--pe-color-lunar-white);
}

.footer-divider {
  border: 0;
  height: 2px;
  background: #C0C0C0; /* Silver color */
  margin: 0 auto; /* Remove vertical margins */
  width: 100%; /* Ensure it spans the full width */
}

.footer-lower {
  border: none;
  outline: none;
  display: flex;
  justify-content: center; /* Center the content */
  margin: 0px;
  padding: 20px 20px;
  color: var(--pe-color-lunar-white);
  font-size: 0.9rem;
}

.footer-row {
  align-items: center;
  padding: 20px 0;
}

.bottom {
  width: 100%; /* Ensure this div takes the full width of its parent */
  display: flex;
  justify-content: space-between; /* Space items out */
  align-items: center;
}

.footer-left {
  text-align: left;
}

.footer-icons {
  text-align: right;
}

.footer-icons a {
  text-decoration: none;
  margin: 0 10px;
}

.icon {
  padding: 5px;
  width: 25px;
  height: auto;
  vertical-align: middle;
}
</style>